//Класс для загрузки блока с товарами
export default class Featers {
    static get toolbox() {
        return {
            title: 'Атрибуты',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({data}) {
        this.data = data;
    }

    render() {
        //Массив товаров
        const arrayGoods = this.data && this.data.attributes ? this.data.attributes : [];
        const divRow = document.createElement('div');
        console.log(this.data);
        console.log(arrayGoods.length);

        if (arrayGoods.length == 0) {
            console.log('arrayGoods == 0');

            divRow.classList.add('row');

            const divCol1 = document.createElement('div');
            divCol1.classList.add('col-8');
            const input1 = document.createElement('input');
            input1.placeholder = 'Атрибут';
            input1.type = 'text';
            input1.name = 'attributes[name]';
            divCol1.appendChild(input1);

            const divCol2 = document.createElement('div');
            divCol2.classList.add('col-4');
            const input2 = document.createElement('input');
            input2.placeholder = 'Значение';
            input2.type = 'text';
            input2.name = 'attributes[value]';
            divCol2.appendChild(input2);

            divRow.appendChild(divCol1);
            divRow.appendChild(divCol2);
        } else {
            for (var i = 0; i < arrayGoods.length; i++) {
                var obj = arrayGoods[i];
                var keys = Object.keys(obj);
                var values = Object.values(obj);

                console.log('arrayGoods != 0');
                divRow.classList.add('row');

                const divCol1 = document.createElement('div');
                divCol1.classList.add('col-8');
                const input1 = document.createElement('input');
                input1.placeholder = 'Атрибут';
                input1.type = 'text';
                input1.name = 'attributes[name]';
                divCol1.appendChild(input1);

                const divCol2 = document.createElement('div');
                divCol2.classList.add('col-4');
                const input2 = document.createElement('input');
                input2.placeholder = 'Значение';
                input2.type = 'text';
                input2.name = 'attributes[value]';
                divCol2.appendChild(input2);

                divRow.appendChild(divCol1);
                divRow.appendChild(divCol2);

                for (var j = 0; j < keys.length; j++) {
                    var key = keys[j],
                        value = values[j],
                        option = document.createElement("option");

                    console.log("Ключ:", key);
                    console.log("Значение:", value);

                    if (key=='name') {
                        input1.value = value
                    } else {
                        input2.value = value
                    }
                }
            }
        }


        return divRow;
    }

    //Сохранение блока
    save(blockContent) {
        console.log(blockContent);

        // Получаем все инпуты на странице
        const inputs = blockContent.querySelectorAll('input');

        // Инициализируем массив для хранения значений
        const arrayResult = [];

        // Проходимся по каждому инпуту
        inputs.forEach((input, index) => {
            // Проверяем, является ли индекс чётным
            if (index % 2 === 0) {
                // Получаем значение следующего инпута
                const nextInput = inputs[index + 1];
                const value = nextInput ? nextInput.value : null;

                // console.log(input.value);
                // console.log(value);
                // Добавляем значения в массив
                if (input.value!='' || value!='') {
                    arrayResult.push({
                        name: input.value,
                        value: value
                    });
                }
            }
        });

        // Выводим результат в консоль
        // console.log(arrayResult);
        return {
            attributes: arrayResult
        }


        //Массив ввида ключ - ид товара и значание - название товара

    }
}