//Класс для загрузки блока с товарами
export default class Accordion {
    static get toolbox() {
        return {
            title: 'Аккордеон',
            icon: '<svg fill="#000000" height="15px" width="15px"  style="padding: 3px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
                '\t viewBox="0 0 297 297" xml:space="preserve">\n' +
                '<g>\n' +
                '\t<path d="M249.92,93.989c2.654,0,5.259-1.08,7.135-2.957c1.879-1.878,2.957-4.482,2.957-7.138c0-2.654-1.078-5.249-2.957-7.135\n' +
                '\t\tc-1.876-1.879-4.481-2.96-7.135-2.96c-2.656,0-5.26,1.081-7.138,2.96c-1.877,1.876-2.957,4.48-2.957,7.135\n' +
                '\t\tc0,2.655,1.08,5.26,2.957,7.138C244.66,92.909,247.254,93.989,249.92,93.989z"/>\n' +
                '\t<path d="M249.92,185.955c2.654,0,5.259-1.069,7.135-2.957c1.879-1.878,2.957-4.481,2.957-7.138c0-2.654-1.078-5.249-2.957-7.136\n' +
                '\t\tc-1.876-1.878-4.481-2.956-7.135-2.956c-2.656,0-5.26,1.078-7.138,2.956c-1.877,1.877-2.957,4.482-2.957,7.136\n' +
                '\t\tc0,2.656,1.08,5.26,2.957,7.138C244.66,184.885,247.264,185.955,249.92,185.955z"/>\n' +
                '\t<path d="M249.92,139.978c2.654,0,5.249-1.081,7.135-2.96c1.879-1.876,2.957-4.48,2.957-7.135c0-2.665-1.078-5.259-2.957-7.138\n' +
                '\t\tc-1.876-1.877-4.481-2.956-7.135-2.956c-2.666,0-5.26,1.079-7.138,2.956c-1.877,1.879-2.957,4.483-2.957,7.138\n' +
                '\t\tc0,2.654,1.08,5.259,2.957,7.135C244.66,138.897,247.264,139.978,249.92,139.978z"/>\n' +
                '\t<path d="M249.92,231.943c2.654,0,5.259-1.08,7.135-2.958c1.879-1.878,2.957-4.482,2.957-7.137s-1.078-5.259-2.957-7.137\n' +
                '\t\tc-1.876-1.877-4.481-2.957-7.135-2.957c-2.666,0-5.26,1.08-7.138,2.957c-1.877,1.878-2.957,4.482-2.957,7.137\n' +
                '\t\ts1.08,5.259,2.957,7.137C244.66,230.863,247.254,231.943,249.92,231.943z"/>\n' +
                '\t<path d="M286.798,18.442h-71.684L173.992,0.801c-0.059-0.025-0.115-0.033-0.172-0.047c-3.492-1.426-7.497-0.809-10.381,1.662\n' +
                '\t\tl-38.253,32.82L87.937,19.258c-0.001-0.002-0.002-0.002-0.003-0.002c-1.22-0.523-2.564-0.814-3.977-0.814H10.2\n' +
                '\t\tc-5.573,0-10.093,4.519-10.093,10.092V268.45c0,5.574,4.52,10.093,10.093,10.093h71.684l41.122,17.64\n' +
                '\t\tc1.275,0.547,2.63,0.817,3.979,0.817c1.945,0,3.883-0.563,5.557-1.667c0.357-0.234,0.695-0.491,1.016-0.766l0,0l38.254-32.818\n' +
                '\t\tl37.25,15.978h0.001c1.222,0.522,2.566,0.815,3.978,0.815h73.759c5.574,0,10.095-4.519,10.095-10.093V28.534\n' +
                '\t\tC296.892,22.961,292.372,18.442,286.798,18.442z M20.295,38.629h53.57v35.173H47.08c-5.574,0-10.092,4.521-10.092,10.095\n' +
                '\t\tc0,5.575,4.518,10.095,10.092,10.095h26.785v16.726H47.08c-5.574,0-10.092,4.519-10.092,10.093c0,5.575,4.518,10.094,10.092,10.094\n' +
                '\t\th26.785v35.178H47.08c-5.574,0-10.092,4.518-10.092,10.093c0,5.575,4.518,10.094,10.092,10.094h26.785v16.726H47.08\n' +
                '\t\tc-5.574,0-10.092,4.52-10.092,10.095c0,5.574,4.518,10.095,10.092,10.095h26.785v35.174h-53.57V38.629z M94.052,43.848l22.84,9.797\n' +
                '\t\tv217.946l-22.84-9.795V43.848z M137.08,51.631l22.838-19.594v213.316l-22.838,19.594V51.631z M180.106,25.391l22.84,9.797v217.948\n' +
                '\t\tl-22.84-9.796V25.391z M276.703,258.356h-53.57V38.629h53.57V258.356z"/>\n' +
                '</g>\n' +
                '</svg>'
        };
    }

    constructor({data}){
        this.data = data;
    }

    render(){
        //Массив товаров
        const arrayAccordion = this.data && this.data.accordion ? this.data.accordion : [];

        let Random = Math.floor(Math.random() * 1000);
        //Добавление названия
        const Name = document.createElement("input");
        Name.classList = "accordion--name mb-2";
        Name.setAttribute("type", "text");
        Name.setAttribute("placeholder", "Название");
        if (arrayAccordion.name === undefined) {
            Name.value = '';
        } else {
            Name.value = arrayAccordion.name;
        }
        //Добавление описание
        const Text = document.createElement("textarea");
        Text.classList = "accordion--text mb-2";
        Text.setAttribute("placeholder", "Описание");
        if (arrayAccordion.text === undefined) {
            Text.value = '';
        } else {
            Text.value = arrayAccordion.text;
        }
        //Добавление открыть
        const Checkbox = document.createElement("input");
        Checkbox.classList = "accordion--checkbox";
        Checkbox.id = Random;
        Checkbox.setAttribute("type", "checkbox");
        if (arrayAccordion.checkbox === undefined) {
            Checkbox.checked = false;
        } else {
            Checkbox.checked = arrayAccordion.checkbox;
        }
        //Добавление открыть
        const Label = document.createElement("label");
        Label.classList = "accordion--label";
        Label.innerHTML = "Открыть по умолчанию";
        Label.setAttribute("for", Random);
        const List = document.createElement("div");
        List.append(Name, Text, Checkbox, Label)
        //Добавление значений из массива


        return List;
    }

    //Сохранение блока
    save(blockContent){
        console.log(blockContent);
        let Name = blockContent.getElementsByClassName('accordion--name')[0].value;
        let Text = blockContent.getElementsByClassName('accordion--text')[0].value;
        let Checkbox = blockContent.getElementsByClassName('accordion--checkbox')[0].checked;
        let arrayResult = {name: Name, text: Text,checkbox: Checkbox}
       console.log(arrayResult);

        //Массив ввида ключ - ид товара и значание - название товара
        return {
            accordion: arrayResult
        }
    }
}