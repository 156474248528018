//Класс для загрузки блока с товарами
export default class Featers {
    static get toolbox() {
        return {
            title: 'Упаковка',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({data}) {
        this.data = data;
    }

    render() {
        //Массив товаров
        const arrayGoods = this.data && this.data.attributes ? this.data.attributes : [];
        const divRow = document.createElement('div');
        console.log(this.data);
        console.log(arrayGoods.length);

        if (arrayGoods.length == 0) {
            divRow.classList.add('row');

            const divCol1 = document.createElement('div');
            divCol1.classList.add('col-4');
            const input1 = document.createElement('input');
            input1.placeholder = 'Название';
            input1.type = 'text';
            input1.name = 'package';
            divCol1.appendChild(input1);

            const divCol2 = document.createElement('div');
            divCol2.classList.add('col-2');
            const input2 = document.createElement('input');
            input2.placeholder = 'Вес,г.';
            input2.type = 'text';
            input2.name = 'weight';
            divCol2.appendChild(input2);

            const divCol3 = document.createElement('div');
            divCol3.classList.add('col-2');
            const input3 = document.createElement('input');
            input3.placeholder = 'Длина,мм.';
            input3.type = 'text';
            input3.name = 'length';
            divCol3.appendChild(input3);

            const divCol4 = document.createElement('div');
            divCol4.classList.add('col-2');
            const input4 = document.createElement('input');
            input4.placeholder = 'Ширина,мм.';
            input4.type = 'text';
            input4.name = 'width';
            divCol4.appendChild(input4);

            const divCol5 = document.createElement('div');
            divCol5.classList.add('col-2');
            const input5 = document.createElement('input');
            input5.placeholder = 'Высота,мм.';
            input5.type = 'text';
            input5.name = 'height';
            divCol5.appendChild(input5);

            divRow.appendChild(divCol1);
            divRow.appendChild(divCol2);
            divRow.appendChild(divCol3);
            divRow.appendChild(divCol4);
            divRow.appendChild(divCol5);
        } else {
            for (var i = 0; i < arrayGoods.length; i++) {
                var obj = arrayGoods[i];
                var keys = Object.keys(obj);
                var values = Object.values(obj);

                divRow.classList.add('row');

                const divCol1 = document.createElement('div');
                divCol1.classList.add('col-4');
                const input1 = document.createElement('input');
                input1.placeholder = 'Название';
                input1.type = 'text';
                input1.name = 'package';
                divCol1.appendChild(input1);

                const divCol2 = document.createElement('div');
                divCol2.classList.add('col-2');
                const input2 = document.createElement('input');
                input2.placeholder = 'Вес,г.';
                input2.type = 'text';
                input2.name = 'weight';
                divCol2.appendChild(input2);

                const divCol3 = document.createElement('div');
                divCol3.classList.add('col-2');
                const input3 = document.createElement('input');
                input3.placeholder = 'Длина,мм.';
                input3.type = 'text';
                input3.name = 'length';
                divCol3.appendChild(input3);

                const divCol4 = document.createElement('div');
                divCol4.classList.add('col-2');
                const input4 = document.createElement('input');
                input4.placeholder = 'Ширина,мм.';
                input4.type = 'text';
                input4.name = 'width';
                divCol4.appendChild(input4);

                const divCol5 = document.createElement('div');
                divCol5.classList.add('col-2');
                const input5 = document.createElement('input');
                input5.placeholder = 'Высота,мм.';
                input5.type = 'text';
                input5.name = 'height';
                divCol5.appendChild(input5);

                divRow.appendChild(divCol1);
                divRow.appendChild(divCol2);
                divRow.appendChild(divCol3);
                divRow.appendChild(divCol4);
                divRow.appendChild(divCol5);

                for (var j = 0; j < keys.length; j++) {
                    var key = keys[j],
                        value = values[j],
                        option = document.createElement("option");

                    if (key=='package') {
                        input1.value = value
                    } else if (key=='weight') {
                        input2.value = value
                    } else if (key=='length') {
                        input3.value = value
                    } else if (key=='width') {
                        input4.value = value
                    } else if (key=='height') {
                        input5.value = value
                    }
                }
            }
        }


        return divRow;
    }

    //Сохранение блока
    save(blockContent) {
        console.log(blockContent);

        // Получаем все инпуты на странице
        const inputs = blockContent.querySelectorAll('input');

        // Инициализируем массив для хранения значений
        const arrayResult = [];

        // Проходимся по каждому инпуту
        var obj = {};

        inputs.forEach((input, index) => {
            var key = input.name;

            obj[key] = input.value;

            if ((index + 1) % 5 === 0) {
                arrayResult.push(obj);
                obj = {};
            }
        });

        // Выводим результат в консоль
        // console.log(arrayResult);
        return {
            attributes: arrayResult
        }


        //Массив ввида ключ - ид товара и значание - название товара

    }
}