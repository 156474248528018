import { IconPicture } from '@codexteam/icons';
import { make } from './utils/dom';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, readOnly }) {
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.readOnly = readOnly;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      imageContainer: make('div', [ this.CSS.imageContainer ]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: make('div', this.CSS.imagePreloader),
      caption: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly,
      })
    };

    /**
     * Create base structure
     *  <wrapper>
     *    <image-container>
     *      <image-preloader />
     *    </image-container>
     *    <caption />
     *    <select-file-button />
     *  </wrapper>
     */
    this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
    this.nodes.wrapper.appendChild(this.nodes.caption);
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      caption: 'image-tool__caption',
    };
  };

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  /**
   * Renders tool UI
   *
   * @param {GooodsImageToolData} toolData - saved tool data
   * @returns {Element}
   */

  render(toolData) {
    if (!toolData.file || Object.keys(toolData.file).length === 0) {
      this.toggleStatus(Ui.status.EMPTY);
    } else {
      this.toggleStatus(Ui.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }

  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton() {
    const button = make('div', [ this.CSS.button ]);

    button.innerHTML = this.config.buttonContent || `${IconPicture} ${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  /**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

    this.toggleStatus(Ui.status.UPLOADING);
  }

  /**
   * Hide uploading preloader
   *
   * @returns {void}
   */
  hidePreloader() {
    this.nodes.imagePreloader.style.backgroundImage = '';
    this.toggleStatus(Ui.status.EMPTY);
  }

  /**
   * Shows an image
   *
   * @param {string} url - image source
   * @param {array} dataGoods - image source
   * @returns {void}
   */
  fillImage(url,dataGoods) {
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = 'IMG';

    const attributes = {
      src: url,
    };

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load';

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(Ui.status.FILLED);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = '';
      }
    });

    this.nodes.imageContainer.appendChild(this.nodes.imageEl);

    let image = this.nodes.imageEl;
    let coordinates = this.nodes.imageContainer;

    image.addEventListener('click', function(event) {
      let imageWidth = image.clientWidth;
      let imageHeight = image.clientHeight;

      let imageX = image.offsetX;
      let imageY = image.offsetY;

      let offsetX = event.offsetX;
      let offsetY = event.offsetY;

      let percentX = (offsetX / imageWidth) * 100;
      let percentY = (offsetY / imageHeight) * 100;
      const option_goods = document.createElement("span");

      var countSpan = this.parentElement.querySelectorAll('span');
      console.log(option_goods);
      console.log(countSpan.length);
      if (countSpan.length > 0) {
        countSpan = countSpan.length + 1;
      } else {
        countSpan = 1;
      }
      option_goods.innerHTML = countSpan;
      option_goods.classList = 'red-ball';
      option_goods.setAttribute("style", "left:" + percentX+'%;' +  "top:" + percentY+"%;");

      coordinates.appendChild(option_goods);

      const input = document.createElement('input');
      const label = document.createElement('label');
      input.dataset.number = countSpan;
      input.dataset.percentX = percentX;
      input.dataset.percentY = percentY;
      input.type = 'text';
      input.classList = 'option-goods-set'
      label.innerHTML = 'Код товара для точки №' + countSpan;
      coordinates.parentElement.appendChild(label);
      coordinates.parentElement.appendChild(input);
      //coordinates.textContent = 'X: ' + percentX.toFixed(2) + '%, Y: ' + percentY.toFixed(2) + '%';
    });

    console.log('images show');
  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text) {
    if (this.nodes.caption) {
      this.nodes.caption.innerHTML = text;
    }
  }
  /**
   * Shows goods inputs
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillGoods(goods) {
    if (goods.length > 0) {
      console.log(goods);
      let image = this.nodes.imageEl;
      let coordinates = this.nodes.imageContainer;

      for (var i = 0; i < goods.length; i++) {
        var obj = goods[i];
        var keys = Object.keys(obj); //Порадковый номер
        var values = Object.values(obj); //Массив с информацией
        var countSpan = keys;
        var percentX = values[0][0];
        var percentY = values[0][1];
        var good_id =  values[0][2];


        const option_goods = document.createElement("span");
        option_goods.innerHTML = countSpan;
        option_goods.classList = 'red-ball';
        option_goods.setAttribute("style", "left:" + percentX+'%;' +  "top:" + percentY+"%;");

        coordinates.appendChild(option_goods);

        const label = document.createElement('label');
        const input = document.createElement('input');
        input.dataset.number = countSpan;
        input.dataset.percentX = percentX;
        input.dataset.percentY = percentY;
        input.type = 'text';
        input.value = good_id;
        input.classList = 'option-goods-set'
        label.innerHTML = '<div class="mt-3">Код товара для точки №' + countSpan + '</div>';
        coordinates.parentElement.appendChild(label);
        coordinates.parentElement.appendChild(input);
      }
      console.log('goods show');
    }
  }
  /**
   * Changes UI status
   *
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
  toggleStatus(status) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
      }
    }
  }

  /**
   * Apply visual representation of activated tune
   *
   * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
   * @param {boolean} status - true for enable, false for disable
   * @returns {void}
   */
  applyTune(tuneName, status) {
    this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${tuneName}`, status);
  }
}

