$(document).ready(function () {
    //---- Табы простые ----//
    $('.js-tab-trigger').on('click', function() {
        let id = $(this).attr('data-tab'),
        content = $('.js-tab-content[data-tab="'+ id +'"]');

        $('.js-tab-trigger.active').removeClass('active');
        $(this).addClass('active');

        $('.js-tab-content.active').removeClass('active');
        content.addClass('active');
    });

    $('.js-tab-trigger-toggle').on('click', function() {
        let id = $(this).attr('data-tab'),
        content = $('.js-tab-content-toggle[data-tab="'+ id +'"]');

        $(this).toggleClass('active');
        content.toggleClass('active');
    });

    //---- Поп-ап галереи фото в карточке ----//
    $('.js-popup-gallery-open').on('click', function(e) {
        e.preventDefault();
        $('.popup-gallery').addClass('active');
        $('body').addClass('overflow-hidden');
    });

    $('.js-popup-gallery-close').on('click', function(e) {
        $('.popup-gallery').removeClass('active');
        $('body').removeClass('overflow-hidden');
    });



    //----Запуск загруженного видео в карточке товара ----//
    $('.js-video-trigger').on('click', function() {
        if (jQuery("#js-video").get(0).paused) {
            jQuery("#js-video").trigger('play');
            jQuery(".play").fadeOut(500);
        } else {

            jQuery("#js-video").trigger('pause');
            jQuery(".play").fadeIn(500);

        }
    });


    function readCookie(name) {
        var name_cook = name+"=";
        var spl = document.cookie.split(";");

        for(var i=0; i<spl.length; i++) {
            var c = spl[i];
            while(c.charAt(0) == " ") {
                c = c.substring(1, c.length);
            }

            if(c.indexOf(name_cook) == 0) {
                return c.substring(name_cook.length, c.length);
            }
        }
        return null;
    }

    var value_cookie = readCookie("first_enter");
    if (value_cookie=='yes') {
        $('.youtube-popuplink').click();
    }

    //активная ссылка
    $('.js-active-menu [href]').each(function() {
        if (this.href == window.location.href) {
        $(this).addClass('active');
        }
    });

    //--Поиск по фильтру--/
    var elements = document.querySelectorAll('.js-form-search');
    for (let i = 0; i < elements.length; ++i) {
        let newId = elements[i].dataset.searchid;

        let elbody = document.getElementById('js-form-search-body-' + newId);
        let elinput = document.getElementById('js-form-search-input-' + newId);

        if($(window).width() >= 768){
            if (elbody.clientHeight > 130) {
                elbody.classList.toggle('sidebar__max-height');
                elbody.classList.toggle('scrollbar--blue');
                elinput.classList.add('d-block');
            };
        };


        elements[i].onkeyup = function () {
            find();
        };
    }

    $('.form-search-input').on("click", function (event) {
        let eventid = event.target.id;
        let eventbodyid = document.getElementById(eventid).nextElementSibling.id;

        let find = function () {
            let inp = document.getElementById(eventid);//сам инпут
            let parent = document.getElementById(eventbodyid),//блок в котором будем искать
                divs = parent.getElementsByTagName('label'),//блок где находится непосредственно строки, которые нужно найти
                len = divs.length;

            for (var i = 0; i < len; i++) {//for begin
                if (divs[i].childNodes.length == 1 && divs[i].innerHTML.toUpperCase().includes(inp.value.toUpperCase()) == false && inp.value != '') {
                    divs[i].style.display = 'none';
                    divs[i].closest("div").style.display = 'none';
                } else if (divs[i].style.display != 'block') {
                    divs[i].style.display = 'block';
                    divs[i].closest("div").style.display = 'block';
                }
            }//for end

        }//find end

        document.onkeyup = function () {
            find();
        };
    });

    let listProgress = $('.rating__progress');

    listProgress.each(function(){
       let allProgress = $(this).attr('data-all'),
            colProgress = $(this).attr('data-progress'),
            itogo = (100 / allProgress) * colProgress;
        $(this).children('span').css('width', itogo + '%');

    });

    //---- Аккордеон ----//
    $('.accordion__title').on('click', function() {
        let id = $(this).attr('data-accordion'),
        content = $('.accordion__description[data-accordion="'+ id +'"]');

        $(this).toggleClass('accordion--true');
        content.toggleClass('accordion--true');
    });

    $('.js-popup-link').on('click', function() {
        let id = $(this).attr('data-popup');
        let content = $('.js-popup-content[data-popup="'+ id +'"]');

        content.addClass('active');
    });

    $('.js-popup-close').on('click', function() {
        let id = $(this).attr('data-popup');
        let content = $('.js-popup-content[data-popup="'+ id +'"]');

        if (id == 'person') {
            $('.js-popup-showName').html($('#order_name').val());
            $('.js-popup-showPhone').html($('#order_phone').val());
        }
        content.removeClass('active');
    });

});
