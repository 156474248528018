import {Controller} from "@hotwired/stimulus";
import EditorJS from "@editorjs/editorjs";

// These are the plugins
// import CodeTool from "@editorjs/code";
import Header from "@editorjs/header";
import ImageTool from "@editorjs/image";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Table from '@editorjs/table';
import Carousel from '@vtchinh/gallery-editorjs';
import editorjsColumns from '@calumk/editorjs-columns';
import Quote from '@editorjs/quote';
import Goods from '/app/javascript/controllers/editorGoods.js'
import Featers from '/app/javascript/controllers/editorFeaters.js'
import Package from '/app/javascript/controllers/editorPackage.js'
import GooodsImageTool from "/app/javascript/controllers/GoodImages/index";
import AttachesTool from '@editorjs/attaches';

// Connects to data-controller="editor"
export default class extends Controller {
    // static targets = ["post_content"];

    connect() {
        function csrfToken() {
            const metaTag = document.querySelector("meta[name='csrf-token']");
            return metaTag ? metaTag.content : "";
        }

        function getInitialContent(id) {
            const hiddenContentField = document.getElementById(id);
            if (hiddenContentField && hiddenContentField.value) {
                return JSON.parse(hiddenContentField.value);
            }
            return {};
        }

        // console.log("Hello, Stimulus!", this.element);

        $(".editor-field").each(function (i) {

            let fieldId = $(this).attr("id"), // Получаем идентификатор поля
                fieldIdHidden = fieldId + '_hidden',
                fieldData = $(this)[0].dataset.tools;
            var defaultBlock = 'paragraph';

            if (fieldData ==  'goodsFeaters') {
                var config = {
                    paragraph: false,
                    featers: Featers,
                }
                var defaultBlock = 'featers';
            } else if (fieldData ==  'goodsPackage') {
                var config = {
                    paragraph: false,
                    package: Package,
                }
                var defaultBlock = 'package';
            } else if (fieldData ==  'goodsDocument') {
                function uploadFile(file) {
                    var formData = new FormData();
                    formData.append('file', file);

                    $.ajax({
                        url: '/goods/upload_media?id=' + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                        type: 'POST',
                        data: formData,
                        dataType: 'json',
                        success: function (response) {
                            // Обработка успешного ответа от сервера
                            console.log(response);
                        },
                        error: function (error) {
                            // Обработка ошибки
                            console.log(error);
                        }
                    });
                }

                var config = {
                    paragraph: false,
                    attaches: {
                        class: AttachesTool,
                        config: {
                            endpoint: '/goods/upload_media?id=' + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type, // Устанавливаем endpoint для загрузки файлов
                            additionalRequestHeaders: {
                                "X-CSRF-Token": csrfToken(),
                            },
                        },
                    },
                };
                var defaultBlock = 'attaches';

            } else if (fieldData ==  'goodsDrawing') {
                var config = {
                    paragraph: false,
                    image: {
                    class: ImageTool,
                        config: {
                        endpoints: {
                            byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                        },
                        additionalRequestHeaders: {
                            "X-CSRF-Token": csrfToken(),
                        },
                    },
                },
                };
                var defaultBlock = 'image';
            } else if (fieldData ==  'goodsInterier') {
                var config = {
                    paragraph: false,
                    goodsimage: {
                        class: GooodsImageTool,
                        config: {
                            endpoints: {
                                byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                            },
                            additionalRequestHeaders: {
                                "X-CSRF-Token": csrfToken(),
                            },
                        },
                    },
                };
                var defaultBlock = 'goodsimage';
            } else {
                var config =  {
                        header: {
                            class: Header,
                        },
                        paragraph: {
                            class: Paragraph,
                            config: {
                                inlineToolbar: true,
                            },
                        },
                        list: {
                            class: List,
                        },
                        quote: {
                            class: Quote,
                            config: {
                                quotePlaceholder: 'Введите цитату',
                                captionPlaceholder: 'Автор цитаты',
                            },
                        },
                        table: Table,
                        goods: Goods,
                        goodsimage: {
                            class: GooodsImageTool,
                            config: {
                                endpoints: {
                                    byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type='  + $(this)[0].dataset.type,
                                },
                                additionalRequestHeaders: {
                                    "X-CSRF-Token": csrfToken(),
                                },
                            },
                        },
                        image: {
                            class: ImageTool,
                            config: {
                                endpoints: {
                                    byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                                },
                                additionalRequestHeaders: {
                                    "X-CSRF-Token": csrfToken(),
                                },
                            },
                        },
                        carousel: {
                            class: Carousel,
                            placeholder: 'Header',
                            config: {
                                endpoints: {
                                    byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                                },
                                additionalRequestHeaders: {
                                    "X-CSRF-Token": csrfToken(),
                                },
                                field: 'image',
                                types: 'image/*',
                                additionalRequestData: { // for custom data
                                    name: 'your custom data name',
                                    order_data: 'your order custom data',
                                },
                                galleryCallback: 'your_prefer_callback_data' // object return is required
                            }
                        },
                        columns: {
                            class: editorjsColumns,
                            config: {
                                tools: {
                                    header: Header,
                                    paragraph: Paragraph,
                                    image: {
                                        class: ImageTool,
                                        config: {
                                            endpoints: {
                                                byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                                            },
                                            additionalRequestHeaders: {
                                                "X-CSRF-Token": csrfToken(),
                                            },
                                        },
                                    },
                                    quote: {
                                        class: Quote,
                                        config: {
                                            quotePlaceholder: 'Введите цитату',
                                            captionPlaceholder: 'Автор цитаты',
                                        },
                                    },
                                    goodsimage: {
                                        class: GooodsImageTool,
                                        config: {
                                            endpoints: {
                                                byFile: `/goods/upload_media?id=` + $(this)[0].dataset.id + '&type=' + $(this)[0].dataset.type,
                                            },
                                            additionalRequestHeaders: {
                                                "X-CSRF-Token": csrfToken(),
                                            },
                                        },
                                    },
                                },
                                EditorJsLibrary: EditorJS //ref EditorJS - This means only one global thing
                            }
                        },
                    };
            }

            const initialContent = getInitialContent(fieldIdHidden);
            console.log(fieldData);
            console.log(config);
            var contentEditor = new EditorJS({
                // Holder is the target element
                holder: fieldId,
                data: initialContent,
                autofocus: false,
                defaultBlock: defaultBlock,
                tools: config,
                i18n: {
                    /**
                     * @type {I18nDictionary}
                     */
                    messages: {
                        /**
                         * Other below: translation of different UI components of the editor.js core
                         */
                        ui: {
                            "blockTunes": {
                                "Filter": "Поиск",
                                "toggler": {
                                    "Click to tune": "Нажмите, чтобы настроить",
                                    "or drag to move": "или перетащите"
                                },
                            },
                            "inlineToolbar": {
                                "converter": {
                                    "Convert to": "Конвертировать в"
                                }
                            },
                            "toolbar": {
                                "toolbox": {
                                    "Add": "Добавить"
                                }
                            }
                        },

                        /**
                         * Section for translation Tool Names: both block and inline tools
                         */
                        toolNames: {
                            "Text": "Параграф",
                            "Heading": "Заголовок",
                            "List": "Список",
                            "Warning": "Примечание",
                            "Checklist": "Чеклист",
                            "Quote": "Цитата",
                            "Image": "Изображение",
                            "Carousel": "Галлерея",
                            "Code": "Код",
                            "Columns": "Колонки",
                            "Delimiter": "Разделитель",
                            "Raw HTML": "HTML-фрагмент",
                            "Table": "Таблица",
                            "Link": "Ссылка",
                            "Marker": "Маркер",
                            "Bold": "Полужирный",
                            "Italic": "Курсив",
                            "InlineCode": "Моноширинный",
                        },

                        /**
                         * Section for passing translations to the external tools classes
                         */
                        tools: {
                            "list": {
                                "Unordered": "Маркированные",
                                "Ordered": "Нумерованные",
                            },
                            "table": {
                                "Add column to left": "Добавить столбец слева",
                                "Add column to right": "Добавить столбец справа",
                                "Delete column": "Удалить столбец",
                                "Add row above": "Добавьте строку выше",
                                "Add row below": "Добавьте строку ниже",
                                "Delete row": "Удалить строку",
                                "Without headings": "Без заголовков",
                                "With headings": "С заголовками",
                            },
                            /**
                             * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
                             * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
                             */
                            "header": {
                                "Heading 1": "Заголовок 1",
                                "Heading 2": "Заголовок 2",
                                "Heading 3": "Заголовок 3",
                                "Heading 4": "Заголовок 4",
                                "Heading 5": "Заголовок 5",
                                "Heading 6": "Заголовок 6",
                            },
                            "columns": {
                                "2 Columns": "2 колонки",
                                "3 Columns": "3 колонки",
                                "Roll Colls": "Отзеркалить",
                            },
                            "image": {
                                "Select an Image": "Выбрать",
                                "Add Image": "Добавить",
                                "Caption": "Подпись",
                            },
                            "carousel": {
                                "Select an Image": "Добавить",
                                "Caption": "Подпись",
                            },
                            "warning": { // <-- 'Warning' tool will accept this dictionary section
                                "Title": "Название",
                                "Message": "Сообщение",
                            },
                            /**
                             * Link is the internal Inline Tool
                             */
                            "link": {
                                "Add a link": "Вставьте ссылку"
                            },
                            /**
                             * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
                             */
                            "stub": {
                                'The block can not be displayed correctly.': 'Блок не может быть отображен'
                            }
                        },

                        /**
                         * Section allows to translate Block Tunes
                         */
                        blockTunes: {
                            /**
                             * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
                             * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
                             *
                             * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
                             */
                            "delete": {
                                "Delete": "Удалить"
                            },
                            "moveUp": {
                                "Move up": "Переместить вверх"
                            },
                            "moveDown": {
                                "Move down": "Переместить вниз"
                            }
                        },
                    }
                },
                //Обработчик события onChange
                onChange: function () {
                    // Получение текущего состояния редактора
                    contentEditor.save()
                        .then((outputData) => {
                            // Преобразование данных в JSON
                            var jsonData = JSON.stringify(outputData);

                            // Сохранение JSON в отдельное поле (например, скрытое поле с id fieldIdHidden)
                            document.getElementById(fieldIdHidden).value = jsonData;
                        });
                }
            });
        });
    }
}
