//Класс для загрузки блока с товарами
export default class Goods {
    static get toolbox() {
        return {
            title: 'Товары',
            icon: '<svg height="15px" width="15px"  style="padding: 3px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px;}</style></defs><path class="cls-1" d="M6.27,7.23A5.73,5.73,0,0,1,12,1.5h0a5.73,5.73,0,0,1,5.73,5.73V22.5H6.27V14.86l5.21-3.25a5.18,5.18,0,0,0,2.43-4.38h0A1.91,1.91,0,0,0,12,5.32h0a1.91,1.91,0,0,0-1.91,1.91Z"/></svg>'
        };
    }

    constructor({data}){
        this.data = data;
    }

    render(){
        //Массив товаров
        const arrayGoods = this.data && this.data.goods ? this.data.goods : [];

        console.log('data:' + this.data);
        //Добавление селекта
        const selectList = document.createElement("select");
        selectList.classList = "tselect-multi";
        selectList.setAttribute("multiple", "multiple");
        selectList.setAttribute("data-placeholder", "Выберите товар");
        selectList.setAttribute("data-ajax--url", "/good/searchgood");
        selectList.setAttribute("data-ajax--dataType", "json");
        selectList.setAttribute("data-width", "100%");

        //Добавление значений из массива
        for (var i = 0; i < arrayGoods.length; i++) {
            var obj = arrayGoods[i];
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            for (var j = 0; j < keys.length; j++) {
                var key = keys[j],
                    value = values[j],
                    option = document.createElement("option");

                console.log("Ключ:", key);
                console.log("Значение:", value);

                option.value = key;
                option.text = value;
                option.setAttribute("selected", "selected");
                selectList.appendChild(option);
            }
        }
        //Запуск плагина select2 для оформления и аякс поиска
        function tselect() {
            $(".tselect-multi").select2({
                theme: "tselect-md",
            });
        }
        setTimeout(tselect, 500);

        return selectList;
    }

    //Сохранение блока
    save(blockContent){
        // Выборка активных опций у селекта
        function getSelectValues(select) {
            var result = [];
            var options = select && select.options;
            var opt;

            for (var i=0, iLen=options.length; i<iLen; i++) {
                opt = options[i];
                if (opt.selected) {
                    if (opt.text=='') {
                        continue
                    }
                    var key = opt.value; // предположим, что данные содержат ключи
                    var value = opt.text; // предположим, что данные содержат значения

                    var obj = {};
                    obj[key] = value;

                    result.push(obj);
                }
            }
            return result;
        }
        var arrayResult = getSelectValues(blockContent.parentElement.querySelector('select'));

        //Массив ввида ключ - ид товара и значание - название товара
        return {
            goods: arrayResult
        }
    }
}