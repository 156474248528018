/**
 * Image Tool for the Editor.js
 *
 * @author CodeX <team@codex.so>
 * @license MIT
 * @see {@link https://github.com/editor-js/image}
 *
 * To developers.
 * To simplify Tool structure, we split it to 4 parts:
 *  1) index.js — main Tool's interface, public API and methods for working with data
 *  2) uploader.js — module that has methods for sending files via AJAX: from device, by URL or File pasting
 *  3) ui.js — module for UI manipulations: render, showing preloader, etc
 *  4) tunes.js — working with Block Tunes: render buttons, handle clicks
 *
 * For debug purposes there is a testing server
 * that can save uploaded files and return a Response {@link UploadResponseFormat}
 *
 *       $ node dev/server.js
 *
 * It will expose 8008 port, so you can pass http://localhost:8008 with the Tools config:
 *
 * image: {
 *   class: GooodsImageTool,
 *   config: {
 *     endpoints: {
 *       byFile: 'http://localhost:8008/uploadFile',
 *       byUrl: 'http://localhost:8008/fetchUrl',
 *     }
 *   },
 * },
 */

/**
 * @typedef {object} GooodsImageToolData
 * @description Image Tool's input and output data format
 * @property {string} caption — image caption
 * @property {boolean} withBorder - should image be rendered with border
 * @property {boolean} withBackground - should image be rendered with background
 * @property {boolean} stretched - should image be stretched to full width of container
 * @property {object} file — Image file data returned from backend
 * @property {string} file.url — image URL
 */

import './index.css';

import Ui from './ui';
import Uploader from './uploader';

import {IconAddBorder, IconStretch, IconAddBackground, IconPicture} from '@codexteam/icons';

/**
 * @typedef {object} ImageConfig
 * @description Config supported by Tool
 * @property {object} endpoints - upload endpoints
 * @property {string} endpoints.byFile - upload by file
 * @property {string} endpoints.byUrl - upload by URL
 * @property {string} field - field name for uploaded image
 * @property {string} types - available mime-types
 * @property {string} captionPlaceholder - placeholder for Caption field
 * @property {object} additionalRequestData - any data to send with requests
 * @property {object} additionalRequestHeaders - allows to pass custom headers with Request
 * @property {string} buttonContent - overrides for Select File button
 * @property {object} [uploader] - optional custom uploader
 * @property {function(File): Promise.<UploadResponseFormat>} [uploader.uploadByFile] - method that upload image by File
 * @property {function(string): Promise.<UploadResponseFormat>} [uploader.uploadByUrl] - method that upload image by URL
 */

/**
 * @typedef {object} UploadResponseFormat
 * @description This format expected from backend on file uploading
 * @property {number} success - 1 for successful uploading, 0 for failure
 * @property {object} file - Object with file data.
 *                           'url' is required,
 *                           also can contain any additional data that will be saved and passed back
 * @property {string} file.url - [Required] image source URL
 */
export default class GooodsImageTool {
    /**
     * Notify core that read-only mode is supported
     *
     * @returns {boolean}
     */
    static get isReadOnlySupported() {
        return true;
    }

    /**
     * Get Tool toolbox settings
     * icon - Tool icon's SVG
     * title - title to show in toolbox
     *
     * @returns {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: '<svg fill="#000000"  height="15px" width="15px"  style="padding: 3px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" \n' +
                '\t viewBox="0 0 502.4 502.4" xml:space="preserve">\n' +
                '<g>\n' +
                '\t<g>\n' +
                '\t\t<path d="M481.067,0.533H21.333C9.6,0.533,0,10.133,0,21.867v458.667c0,11.733,9.6,21.333,21.333,21.333h354.133\n' +
                '\t\t\tc6.4,0,11.733-2.133,17.067-6.4l104.533-121.6c3.2-4.267,5.333-8.533,5.333-13.867V21.867C502.4,10.133,492.8,0.533,481.067,0.533\n' +
                '\t\t\tz M381.867,442.133v-61.867H435.2L381.867,442.133z M459.733,337.6H361.6c-11.733,0-21.333,9.6-21.333,21.333v101.333H41.6V42.133\n' +
                '\t\t\th418.133V337.6z"/>\n' +
                '\t</g>\n' +
                '</g>\n' +
                '<g>\n' +
                '\t<g>\n' +
                '\t\t<path d="M250.667,76.267c-46.933,0-80,39.467-80,94.933c0,50.133,56.533,132.267,62.933,141.867\n' +
                '\t\t\tc4.267,5.333,10.667,8.533,17.067,8.533c6.4,0,12.8-3.2,17.067-8.533c6.4-8.533,62.933-91.733,62.933-141.867\n' +
                '\t\t\tC330.667,115.733,297.6,76.267,250.667,76.267z M250.667,262.933c-19.2-30.933-38.4-70.4-38.4-91.733c0-32,16-53.333,38.4-53.333\n' +
                '\t\t\tc22.4,0,38.4,21.333,38.4,53.333C289.067,192.533,268.8,232,250.667,262.933z"/>\n' +
                '\t</g>\n' +
                '</g>\n' +
                '<g>\n' +
                '\t<g>\n' +
                '\t\t<path d="M183.467,366.4h-40.533v-40.533c0-11.733-9.6-21.333-21.333-21.333s-21.333,9.6-21.333,21.333v60.8\n' +
                '\t\t\tc0,11.733,9.6,21.333,21.333,21.333h60.8c11.733,0,22.4-8.533,22.4-20.267C204.8,376,195.2,366.4,183.467,366.4z"/>\n' +
                '\t</g>\n' +
                '</g>\n' +
                '</svg>',
            title: 'Товары на фото',
        };
    }

    /**
     * Available image tools
     *
     * @returns {Array}
     */
    static get tunes() {
        return [
            {
                name: 'withBorder',
                icon: IconAddBorder,
                title: 'With border',
                toggle: true,
            },
            {
                name: 'stretched',
                icon: IconStretch,
                title: 'Stretch image',
                toggle: true,
            },
            {
                name: 'withBackground',
                icon: IconAddBackground,
                title: 'With background',
                toggle: true,
            },
        ];
    }

    /**
     * @param {object} tool - tool properties got from editor.js
     * @param {GooodsImageToolData} tool.data - previously saved data
     * @param {ImageConfig} tool.config - user config for Tool
     * @param {object} tool.api - Editor.js API
     * @param {boolean} tool.readOnly - read-only mode flag
     */
    constructor({data, config, api, readOnly}) {
        this.api = api;
        this.readOnly = readOnly;

        /**
         * Tool's initial config
         */
        this.config = {
            endpoints: config.endpoints || '',
            additionalRequestData: config.additionalRequestData || {},
            additionalRequestHeaders: config.additionalRequestHeaders || {},
            field: config.field || 'image',
            types: config.types || 'image/*',
            captionPlaceholder: this.api.i18n.t(config.captionPlaceholder || 'Caption'),
            buttonContent: config.buttonContent || '',
            uploader: config.uploader || undefined,
            actions: config.actions || [],
        };

        /**
         * Module for file uploading
         */
        this.uploader = new Uploader({
            config: this.config,
            onUpload: (response) => this.onUpload(response),
            onError: (error) => this.uploadingFailed(error),
        });

        /**
         * Module for working with UI
         */
        this.ui = new Ui({
            api,
            config: this.config,
            onSelectFile: () => {
                this.uploader.uploadSelectedFile({
                    onPreview: (src) => {
                        this.ui.showPreloader(src);
                    },
                });
            },
            readOnly,
        });

        /**
         * Set saved state
         */
        this._data = {};
        this.data = data;
    }

    /**
     * Renders Block content
     *
     * @public
     *
     * @returns {HTMLDivElement}
     */
    render() {
        return this.ui.render(this.data);
    }

    /**
     * Validate data: check if Image exists
     *
     * @param {GooodsImageToolData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        return savedData.file && savedData.file.url;
    }

    /**
     * Return Block data
     *
     * @public
     *
     * @returns {GooodsImageToolData}
     */
    save(blockContent) {
        const caption = this.ui.nodes.caption;

        this._data.caption = caption.innerHTML;


        // Выборка активных опций у селекта

        var result = [];
        var options = blockContent.querySelectorAll('input');
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            var key = opt.dataset.number; // предположим, что данные содержат ключи
            var percentX = opt.dataset.percentX; // предположим, что данные содержат значения
            var percentY = opt.dataset.percentY; // предположим, что данные содержат значения
            var text = opt.value; // предположим, что данные содержат значения
            var obj = {};

            obj[key] = [percentX,percentY,text];

            result.push(obj);
        }

        this._data.goods = result;

        //Массив ввида ключ - ид товара и значание - название товара
        return this.data;
    }

    /**
     * Returns configuration for block tunes: add background, add border, stretch image
     *
     * @public
     *
     * @returns {Array}
     */
    renderSettings() {
        // Merge default tunes with the ones that might be added by user
        // @see https://github.com/editor-js/image/pull/49
        const tunes = GooodsImageTool.tunes.concat(this.config.actions);

        return tunes.map(tune => ({
            icon: tune.icon,
            label: this.api.i18n.t(tune.title),
            name: tune.name,
            toggle: tune.toggle,
            isActive: this.data[tune.name],
            onActivate: () => {
                /* If it'a user defined tune, execute it's callback stored in action property */
                if (typeof tune.action === 'function') {
                    tune.action(tune.name);

                    return;
                }
                this.tuneToggled(tune.name);
            },
        }));
    }

    /**
     * Fires after clicks on the Toolbox Image Icon
     * Initiates click on the Select File button
     *
     * @public
     */
    appendCallback() {
        this.ui.nodes.fileButton.click();
    }

    /**
     * Specify paste substitutes
     *
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
     */
    static get pasteConfig() {
        return {
            /**
             * Paste HTML into Editor
             */
            tags: [
                {
                    img: {src: true},
                },
            ],
            /**
             * Paste URL of image into the Editor
             */
            patterns: {
                image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png|svg|webp)(\?[a-z0-9=]*)?$/i,
            },

            /**
             * Drag n drop file from into the Editor
             */
            files: {
                mimeTypes: ['image/*'],
            },
        };
    }

    /**
     * Specify paste handlers
     *
     * @public
     * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
     * @param {CustomEvent} event - editor.js custom paste event
     *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
     * @returns {void}
     */
    async onPaste(event) {
        switch (event.type) {
            case 'tag': {
                const image = event.detail.data;

                /** Images from PDF */
                if (/^blob:/.test(image.src)) {
                    const response = await fetch(image.src);
                    const file = await response.blob();

                    this.uploadFile(file);
                    break;
                }

                this.uploadUrl(image.src);
                break;
            }
            case 'pattern': {
                const url = event.detail.data;

                this.uploadUrl(url);
                break;
            }
            case 'file': {
                const file = event.detail.file;

                this.uploadFile(file);
                break;
            }
        }
    }

    /**
     * Private methods
     * ̿̿ ̿̿ ̿̿ ̿'̿'\̵͇̿̿\з= ( ▀ ͜͞ʖ▀) =ε/̵͇̿̿/’̿’̿ ̿ ̿̿ ̿̿ ̿̿
     */

    /**
     * Stores all Tool's data
     *
     * @private
     *
     * @param {GooodsImageToolData} data - data in Image Tool format
     */
    set data(data) {
        this.image = data.file;

        this._data.caption = data.caption || '';
        this._data.goods = data.goods || [];
        this.ui.fillCaption(this._data.caption);
        this.ui.fillGoods(this._data.goods);

        GooodsImageTool.tunes.forEach(({name: tune}) => {
            const value = typeof data[tune] !== 'undefined' ? data[tune] === true || data[tune] === 'true' : false;

            this.setTune(tune, value);
        });
    }

    /**
     * Return Tool data
     *
     * @private
     *
     * @returns {GooodsImageToolData}
     */
    get data() {
        return this._data;
    }

    /**
     * Set new image file
     *
     * @private
     *
     * @param {object} file - uploaded file data
     */
    set image(file) {
        this._data.file = file || {};
        this._data.goods = file || {};

        if (file && file.url) {
            this.ui.fillImage(file.url, this._data);
        }
    }

    /**
     * File uploading callback
     *
     * @private
     *
     * @param {UploadResponseFormat} response - uploading server response
     * @returns {void}
     */
    onUpload(response) {
        if (response.success && response.file) {
            this.image = response.file;
        } else {
            this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
        }
    }

    /**
     * Handle uploader errors
     *
     * @private
     * @param {string} errorText - uploading error text
     * @returns {void}
     */
    uploadingFailed(errorText) {
        console.log('Image Tool: uploading failed because of', errorText);

        this.api.notifier.show({
            message: this.api.i18n.t('Couldn’t upload image. Please try another.'),
            style: 'error',
        });
        this.ui.hidePreloader();
    }

    /**
     * Callback fired when Block Tune is activated
     *
     * @private
     *
     * @param {string} tuneName - tune that has been clicked
     * @returns {void}
     */
    tuneToggled(tuneName) {
        // inverse tune state
        this.setTune(tuneName, !this._data[tuneName]);
    }

    /**
     * Set one tune
     *
     * @param {string} tuneName - {@link Tunes.tunes}
     * @param {boolean} value - tune state
     * @returns {void}
     */
    setTune(tuneName, value) {
        this._data[tuneName] = value;

        this.ui.applyTune(tuneName, value);

        if (tuneName === 'stretched') {
            /**
             * Wait until the API is ready
             */
            Promise.resolve().then(() => {
                const blockId = this.api.blocks.getCurrentBlockIndex();

                this.api.blocks.stretchBlock(blockId, value);
            })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    /**
     * Show preloader and upload image file
     *
     * @param {File} file - file that is currently uploading (from paste)
     * @returns {void}
     */
    uploadFile(file) {
        this.uploader.uploadByFile(file, {
            onPreview: (src) => {
                this.ui.showPreloader(src);
            },
        });
    }

    /**
     * Show preloader and upload image by target url
     *
     * @param {string} url - url pasted
     * @returns {void}
     */
    uploadUrl(url) {
        this.ui.showPreloader(url);
        this.uploader.uploadByUrl(url);
    }
}
